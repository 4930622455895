import { handleApiError, handleApiResponse } from "@/methods/api";
import ApiVerified from "@/services/apiVerified";
import {
  AEMSchoolContactFormDisplay,
  AESchoolContactFormInputs,
  SchoolAcademicPlan,
  SchoolAcademicPlanMonthly,
  SchoolAcademicPlanMonthlyInputs,
  SchoolInfrastructure,
  SchoolMonthlyMeetingDisplay,
  SchoolMonthlyMeetingInputs,
  SchoolMonthlyMeetingUpdate,
  SchoolProfileDisplay,
  SchoolProfileInputs,
  SchoolQueryForm,
  SchoolUser,
  SchoolUsers,
  UpdateSchoolProfile,
} from "@/types/admin";
import { ApiResponse, IdNamePair } from "@/types/global";
import { UpdateTutoringStudentPaymentInputs } from "@/types/tutoring";

export default class SchoolProfileApi extends ApiVerified {
  async saveSchoolQueryForm(schoolQueryForm: SchoolQueryForm): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/school-profile/save-school-query-form`,
        schoolQueryForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateSchoolQueryForm(schoolQueryForm: SchoolQueryForm): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/school-profile/update-school-query-form`,
        schoolQueryForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolQueryFormsForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolQueryForm>>(
        `school-profile/get-school-query-forms-for-affiliation`,
        { params: { affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolQueryFormsForOwner(ownerId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolQueryForm>>(
        `school-profile/get-school-query-forms-for-owner`,
        { params: { ownerId: ownerId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAEMSchoolContactFormsForUser(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AEMSchoolContactFormDisplay>>(
        `school-profile/get-school-contact-forms-for-user`,
        { params: { userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAEMSchoolContactFormsForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<AEMSchoolContactFormDisplay>>(
        `school-profile/get-school-contact-forms-for-affiliation`,
        { params: { affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveAEMSchoolContactForm(
    schoolContactForm: AESchoolContactFormInputs,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/school-profile/save-school-contact-form`,
        schoolContactForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
    0;
  }

  async updateAEMSchoolContactForm(
    schoolContactForm: AESchoolContactFormInputs,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/school-profile/update-school-contact-form`,
        schoolContactForm,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolsForAem(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `school-profile/get-schools-for-aem`,
        {
          params: { userId: userId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolUsersByRole(schoolUserRoleId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `school-profile/get-school-users-by-role`,
        { params: { schoolUserRoleId: schoolUserRoleId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addSchoolAcademicPlan(schoolAcademicPlan: SchoolAcademicPlan): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/add-school-academic-plan`,
        schoolAcademicPlan,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addSchoolProfile(schoolProfileInputs: SchoolProfileInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/add-school-profile`,
        schoolProfileInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addSchoolAcademicPlanMonthly(
    schoolAcademicPlanMonthlyInputs: SchoolAcademicPlanMonthlyInputs,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/add-school-academic-plan-monthly`,
        schoolAcademicPlanMonthlyInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addSchoolUser(schoolUserInputs: SchoolUser): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/add-school-user`,
        schoolUserInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async registerInternalSchoolUser(userId: number, schoolUserRoleId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/register-internal-school-user`,
        {
          userId: userId,
          schoolUserRoleId: schoolUserRoleId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolProfilesForAem(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolProfileDisplay>>(
        `school-profile/get-school-profiles-for-aem`,
        { params: { userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolAcademicPlan(schoolProfileId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SchoolAcademicPlan>(
        `school-profile/get-school-academic-plan`,
        {
          params: { schoolProfileId: schoolProfileId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolUserByEmail(emailId: string, schoolUserRoleId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<IdNamePair>(
        `school-profile/get-school-user-by-email`,
        { params: { emailId: emailId, schoolUserRoleId: schoolUserRoleId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateSchoolProfile(updateSchoolProfile: UpdateSchoolProfile): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/update-school-profile`,
        updateSchoolProfile,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addSchoolInfrastucture(
    schoolProfileId: number,
    schoolInfrastructure: SchoolInfrastructure,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/add-school-infrastructure`,
        {
          schoolProfileId: schoolProfileId,
          schoolInfrastructure: schoolInfrastructure,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolAcademicPlanMonthly(
    schoolProfileId: number,
    startMonth: string,
    endMonth: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolAcademicPlanMonthly>>(
        `school-profile/get-school-academic-plan-monthly`,
        {
          params: { schoolProfileId: schoolProfileId, startMonth: startMonth, endMonth: endMonth },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationsForAem(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `school-profile/get-affiliations-for-aem`,
        { params: { userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUsersByRoleId(roleId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`user/get-users-by-roleId`, {
        params: { roleId: roleId, onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async mapSchoolToAffiliation(
    schoolProfileId: number,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `school-profile/map-school-to-affiliation`,
        {
          schoolProfileId: schoolProfileId,
          affiliationId: affiliationId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async scheduleSchoolMonthlyMeeting(
    schoolMonthlyMeetings: SchoolMonthlyMeetingInputs,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `school-profile/schedule-school-monthly-meeting`,
        schoolMonthlyMeetings,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolMonthlyMeetingsForAem(
    userId: number,
    meetingStatusId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SchoolMonthlyMeetingDisplay>>(
        `school-profile/get-school-monthly-meetings-for-aem`,
        { params: { userId: userId, meetingStatusId: meetingStatusId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSchoolUsersForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SchoolUsers>(
        `school-profile/get-school-users-for-affiliation`,
        { params: { affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateSchoolMonthlyMeeting(
    updateSchoolMeetings: SchoolMonthlyMeetingUpdate,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `school-profile/update-school-monthly-meeting`,
        updateSchoolMeetings,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleSchoolMonthlyMeeting(meetingDatetimeStr: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `school-profile/reschedule-school-monthly-meeting`,
        meetingDatetimeStr,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateSchoolMonthlyMeetingStatus(meetingStatusId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `school-profile/update-school-monthly-meeting-status`,
        meetingStatusId,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
