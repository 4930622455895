<template>
  <div class="modal-container flexcol">
    <div class="modal-header flexrow xbetween ycenter px-3 py-2">
      <div class="flexrow ycenter fs-s">
        <i class="bi bi-bug-fill fs-m tightest color-white me-2"></i>
        <div class="fw-bold color-white">Bug Report</div>
      </div>
      <ModalCloseButton color="danger" />
    </div>
    <div class="modal-body py-3">
      <form
        class="user-form flexcol gap-2 mx-5"
        @submit.prevent="onBugReportSubmit"
        v-bind:validation-schema="AddBugReportSchema"
      >
        <CustomDropdown
          inputId="Category"
          inputLabel="Category"
          :dropdownItems="bugCategoryOptions"
          :isFormField="true"
          :initialValue="addbugReportForm.values.Category"
        />
        <CustomTextArea
          inputId="Description"
          inputLabel="Description"
          :isFormField="true"
          :initialValue="addbugReportForm.values.Description"
        />
        <div>
          <CustomLabel inputId="AddScreenshots" inputLabel="Add Screenshots" />
          <CustomFileUpload
            inputId="AddScreenshots"
            fileType=".jpg,.png"
            fileIcon="filetype-jpg"
            :uploadLimit="3"
            uploadTip="*jpg/png file with size less than 2MB"
            @onChange="onScreenshotUploaded"
            @onRemove="onScreenshotRemove"
          />
        </div>
        <div>
          <CustomLabel inputId="UploadVideo" inputLabel="Upload Video" />
          <CustomFileUpload
            fileType="video/*"
            inputId="UploadVideo"
            fileIcon="filetype-mp4"
            :uploadLimit="1"
            uploadTip="*mp4 file with size less than 10MB"
            @onChange="onVideoUploaded"
          />
        </div>
        <div class="flexrow gap-2 mb-2">
          <div type="submit" class="button success">Submit Bug</div>
          <div class="button danger-reverse" @click="closeModal">Cancel</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import CustomDropdown from "@/components/input/CustomDropdown.vue";
import CustomFileUpload from "@/components/input/CustomFileUpload.vue";
import CustomLabel from "@/components/input/CustomLabel.vue";
import CustomTextArea from "@/components/input/CustomTextArea.vue";
import ModalCloseButton from "@/components/modals/ModalCloseButton.vue";
import { BugCategoriesEnum } from "@/enums/global";
import { getEncodedUploadFile, getIdNamePairsFromEnum } from "@/methods/utils";
import { useModalStore } from "@/stores/modalStore";
import { useSessionStore } from "@/stores/sessionStore";
import { toTypedSchema } from "@vee-validate/yup";
import { UploadUserFile } from "element-plus";
import { storeToRefs } from "pinia";
import { useForm } from "vee-validate";
import { defineComponent, ref } from "vue";
import * as yup from "yup";

export default defineComponent({
  name: "ModalReportBug",
  components: { ModalCloseButton, CustomDropdown, CustomTextArea, CustomFileUpload, CustomLabel },
  setup() {
    const modalStore = useModalStore();
    const fileSource = ref();
    const { closeModal } = modalStore;
    const sessionStore = useSessionStore();
    const { UserInfo } = storeToRefs(sessionStore);
    const bugCategoryOptions = getIdNamePairsFromEnum(BugCategoriesEnum);

    const AddBugReportSchema = toTypedSchema(
      yup.object().shape({
        UserId: yup.number(),
        EmailId: yup.string(),
        UserRoleId: yup.number(),
        ScreenName: yup.string(),
        Category: yup.number().required("Category is required"),
        Description: yup.string().required("Description is required"),
        AddScreenshots: yup.array(yup.string()).min(1).required("Screenshots are required"),
        UploadVideo: yup.string().required("Video is required"),
      }),
    );
    const formValues = ref({
      UserId: UserInfo.value.UserId,
      EmailId: UserInfo.value.EmailId,
      UserRoleId: UserInfo.value.UserRoleId,
      ScreenName: window.location.pathname,
      Category: undefined,
      Description: "",
      AddScreenshots: [] as Array<string>,
      UploadVideo: "",
    });

    const addbugReportForm = useForm({
      validationSchema: AddBugReportSchema,
      initialValues: formValues.value,
    });

    const onBugReportSubmit = addbugReportForm.handleSubmit(async (values, actions) => {
      console.log(values, "values");
    });

    async function onScreenshotUploaded(files: Array<UploadUserFile>) {
      fileSource.value = await getEncodedUploadFile(files);
      formValues.value.AddScreenshots.push(fileSource.value);
      addbugReportForm.setFieldValue("AddScreenshots", formValues.value.AddScreenshots);
    }
    async function onVideoUploaded(files: Array<UploadUserFile>) {
      fileSource.value = await getEncodedUploadFile(files);
      addbugReportForm.setFieldValue("UploadVideo", fileSource.value);
    }
    async function onScreenshotRemove(files: Array<UploadUserFile>) {
      console.log(files, "file");
    }

    return {
      bugCategoryOptions,
      AddBugReportSchema,
      addbugReportForm,
      closeModal,
      onScreenshotUploaded,
      onVideoUploaded,
      onScreenshotRemove,
      onBugReportSubmit,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal-container {
  width: 840px;
  @include media("<=m") {
    width: 90%;
  }
  max-height: 90vh;
  background-color: $color-white;
  border-radius: 10px;
  overflow: hidden;

  & .modal-header {
    background-color: $color-danger;
  }
  & .modal-body {
    overflow-y: scroll;
  }
}
</style>
