import {
  onAllowRotateClicked,
  onEnableCastingClicked,
  onViewDownloadsClicked,
} from "@/methods/utils";
import { ReportCardProp } from "@/types/affiliation";
import { UserAlert } from "@/types/global";

export const CompanyFullname = "Teevra Edutech Pvt Ltd";

export const GooglePlayStoreUrl =
  "https://play.google.com/store/apps/details?id=in.speedlabs.science&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";

export const AppleAppStoreUrl =
  "https://apps.apple.com/in/app/speedlabs/id1606108997?itsct=apps_box_badge&itscg=30200";

export const FacebookUrl = "https://www.facebook.com/speedlabsindia/";
export const LinkedInUrl = "https://www.linkedin.com/company/speedlabs/mycompany/";
export const TwitterUrl = "https://twitter.com/SpeedLabs_India";
export const InstagramUrl = "https://www.instagram.com/speedlabs.india/";
export const YoutubeUrl = "https://www.youtube.com/@SpeedLabs";

export const YoutubeBaseUrl = "https://www.youtube.com/embed/";
export const YoutubeQueryParams =
  "amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1";
export const YoutubeUrlRegex =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const DifficultyColor: { [DifficultyLevel: string]: string } = Object.freeze({
  Easy: "success",
  Medium: "warning",
  Hard: "danger",
});

export const Breakpoints = {
  xxs: 420,
  xs: 575,
  s: 767,
  m: 991,
  l: 1199,
  xl: 1399,
};

export const RouteActionableMenuItems = [
  {
    id: "AllowRotate",
    displayName: "Rotate Screen",
    icon: "arrow-90deg-right",
    function: onAllowRotateClicked,
  },
  {
    id: "EnableCasting",
    displayName: "Enable Casting",
    icon: "cast",
    function: onEnableCastingClicked,
  },
  {
    id: "ViewDownloads",
    displayName: "View Downloads",
    icon: "download",
    function: onViewDownloadsClicked,
  },
];

export const MultiSubjectName = "Multi-Subject";
export const DefaultSubjectIcon = "default";
export const AddBulkUsersCsvTemplateUrl = "~/webapp/AddBulkUsersTemplate.csv";

export const appRefreshAlert = {
  messageTitle: "CRITICAL",
  messageDescription:
    "It seems that you may have an older version of the SpeEdLabs app. We recommend refreshing the page (Ctrl + F5) to update your app.",
  messageType: "error",
  isDark: true,
} as UserAlert;

export const VueMathJaxOptions = {
  extensions: ["tex2jax.js"],
  showProcessingMessages: false,
  jax: ["input/TeX", "output/CommonHTML"],
  showMathMenu: false,
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
    processEscapes: false,
    processClass: "tex2jax_process",
  },
  CommonHTML: {
    scale: 85,
    mtextFontInherit: true,
    linebreaks: { automatic: true },
  },
};

export const MaxAffiliationActivityDays = 365;
export const PixelErrorThreshold = 10;
export const OptionsArray = ["A", "B", "C", "D"];

export const QuestionPdfStatusScreenMapping = Object.freeze({
  2: "VerifyNewQuestionsPdf",
  5: "ReviewNewQuestionImages",
  7: "ReviewNewQuestionImages",
  8: "TagNewQuestions",
});

export const QuestionBankStatusScreenMapping = Object.freeze({
  1: "TagNewQuestions",
  2: "TagNewQuestions",
  5: "ReviewTaggedQuestions",
  6: "ReviewTaggedQuestions",
});

export const MaxManagePreloadedSelections = 5;

export const MaxPracticeChapters = 3;

export const ActionableCardProps = Object.freeze({
  Learn: {
    buttonName: "Watch Video",
    buttonIcon: "camera-reels",
    cardColor: "danger",
    customMessage: "Watch All Videos",
    actionRouteName: "LearnDashboard",
  },
  Practice: {
    buttonName: "Start Practice",
    buttonIcon: "play-circle",
    cardColor: "secondary",
    customMessage: " Start a Practice Session",
    actionRouteName: "SelfPractice",
  },
  SelfTest: {
    buttonName: "Start Test",
    buttonIcon: "stopwatch",
    cardColor: "info-light",
    customMessage: "Start Your Own Test",
    actionRouteName: "CreateSelfTest",
  },
  InstituteTest: {
    buttonName: "Start Test",
    buttonIcon: "hourglass-split",
    cardColor: "primary",
    customMessage: "Start an Institute Test",
    actionRouteName: "InstituteTests",
  },
  Assignment: {
    buttonName: "Start Assignment",
    buttonIcon: "pencil-square",
    cardColor: "success",
    customMessage: "Start an Assignment",
    actionRouteName: "Assignments",
  },
  Concepts: {
    buttonName: "View Concepts",
    buttonIcon: "journal-bookmark-fill",
    cardColor: "secondary",
    customMessage: "Revise Concepts",
    actionRouteName: "LearnDashboard",
  },
  ScheduleClass: {
    buttonName: "Schedule Class",
    buttonIcon: "people",
    cardColor: "warning",
    customMessage: "Schedule a Class",
    actionRouteName: "CreateLiveClass",
  },
  CreateAssignment: {
    buttonName: "Create Assignment",
    buttonIcon: "pencil-square",
    cardColor: "success",
    customMessage: "Create an Assignment",
    actionRouteName: "CreateAssignment",
  },
  CreateTest: {
    buttonName: "Create Test",
    buttonIcon: "hourglass-split",
    cardColor: "primary",
    customMessage: "Create a Test",
    actionRouteName: "CreateInstituteTest",
  },
});

export const PracticeSessionCardProps = Object.freeze({
  Paused: {
    buttonName: "Resume",
    buttonIcon: "play-circle",
    cardColor: "success",
    showMetrics: false,
  },
  Completed: {
    buttonName: "View Report",
    buttonIcon: "clipboard-data",
    cardColor: "gray",
    showMetrics: true,
  },
});

export const SubjectCardButtonProps = Object.freeze({
  Learn: {
    buttonName: "Learn",
    buttonIcon: "lightbulb",
    actionRouteName: "LearnDashboard",
  },
  Pratice: { buttonName: "Pratice", buttonIcon: "pencil-square", actionRouteName: "SelfPractice" },
  SelfTest: {
    buttonName: "Self Test",
    buttonIcon: "alarm",
    actionRouteName: "SelfTests",
  },
  SubjectCoverage: {
    buttonName: "Subject Coverage",
    buttonIcon: "clipboard-data",
    actionRouteName: "ReportSubjectCoverage",
  },
});

export const ReportCardProps: { [reportType: string]: ReportCardProp } = Object.freeze({
  EffortAnalysis: {
    cardName: "Effort Analysis",
    cardColor: "secondary",
    iconName: "metrics-performance",
    reportDescription:
      "Analyze the performance and effort of each student over the last few weeks.",
    routeName: "ReportBatchEffortAnalysis",
  },
  BatchPerformance: {
    cardName: "Batch Performance",
    cardColor: "success",
    iconName: "medal",
    reportDescription:
      "Review an entire batch's progress with student-wise reports and tailored recommendations.",
    routeName: "ReportBatchPerformance",
  },
  SubjectCoverage: {
    cardName: "Syllabus Coverage",
    cardColor: "primary",
    iconName: "percentage",
    reportDescription: "Analyze the chapter-wise coverage of a batch for any subject.",
    routeName: "ReportSyllabusCoverage",
  },
  InstituteTestAnalysis: {
    cardName: "Test Analysis",
    cardColor: "info-light",
    iconName: "trend",
    reportDescription:
      "Detailed results and analysis of students' performance in the institute test.",
    routeName: "ReportBatchTestAnalysis",
  },
});

export const StudyMaterialButtonProps = Object.freeze({
  1: {
    studyMaterialType: "Formulae Sheet",
    buttonIcon: "plus-slash-minus",
    cardColor: "success",
  },
  2: {
    studyMaterialType: "Chapter Notes",
    buttonIcon: "pencil-square",
    cardColor: "success",
  },

  3: {
    studyMaterialType: "Important Qs",
    buttonIcon: "quora",
    cardColor: "success",
  },

  6: {
    studyMaterialType: "Solved Examples",
    buttonIcon: "key",
    cardColor: "success",
  },

  9: {
    studyMaterialType: "Others",
    buttonIcon: "list-ul",
    cardColor: "success",
  },

  10: {
    studyMaterialType: "NCERT Solution",
    buttonIcon: "journal-plus",
    cardColor: "success",
  },
});

export const SchoolAssessmentTypes = [
  "FormativeAssessment",
  "MoY",
  "EoY",
  "National MoY",
  "National EoY",
];

export const SchoolPlanFileTypes = [
  "Academic Plan",
  "Assessment Plan",
  "Assessment Blueprints",
  "School Academic Calendar",
];
