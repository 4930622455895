import NotificationIcon from "@/components/NotificationIcon.vue";
import {
  MultiSubjectName,
  YoutubeBaseUrl,
  YoutubeQueryParams,
  YoutubeUrlRegex,
} from "@/constants/global";
import router from "@/router";
import { useSessionStore } from "@/stores/sessionStore";
import { useSpeedlabsStore } from "@/stores/speedlabsStore";
import {
  CustomMessage,
  CustomNotification,
  CustomTreeNode,
  IdNameChildren,
  IdNamePair,
} from "@/types/global";
import axios from "axios";
import { format, intervalToDuration, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { ElMessage, ElNotification, UploadUserFile } from "element-plus";
import { storeToRefs } from "pinia";
import { h } from "vue";

export async function onAllowRotateClicked() {
  useSpeedlabsStore().callFlutterHandler("onAllowRotate");
}

export async function onEnableCastingClicked() {
  useSpeedlabsStore().callFlutterHandler("onEnableCasting");
}

export async function onViewDownloadsClicked() {
  useSpeedlabsStore().callFlutterHandler("onViewDownloads");
}

export async function onWebAppLogoutClicked() {
  useSpeedlabsStore().callFlutterHandler("gotoLogout");
}

export async function gotoLogout(isSystemExpired: boolean) {
  router.push({ name: "Logout", params: { isSystemExpired: String(isSystemExpired) } });
}

export function gotoDashboard() {
  const sessionStore = useSessionStore();
  router.push({ name: sessionStore.UserInfo.UserDashboard });
}

export function gotoPrevious() {
  router.back();
}

export function gotoRouteWithFlutterEvent(routeName: string) {
  const isSuccess = useSpeedlabsStore().callFlutterHandler(`goto${routeName}`);
  if (isSuccess) {
    return;
  }
  router.push({ name: routeName });
}

export function getAzureUrl(partialUrl: string, randomize: boolean = false): string {
  const baseUrl = import.meta.env.VITE_AZURE_BASE_URL;
  const sessionStore = useSessionStore();
  const fullUrl = partialUrl.startsWith("~")
    ? partialUrl.replace("~", baseUrl)
    : `${baseUrl}/${partialUrl}`;
  const randomString = randomize ? generateRandomString(8) : "";
  const sessionUuid = `${sessionStore.UserInfo.SessionUuid}${randomString}`;
  return `${fullUrl}?session=${sessionUuid}`;
}

export function getImageUrl(imageName: string | undefined | null): string | undefined {
  if (imageName === undefined || imageName === null) {
    return undefined;
  }
  return getAzureUrl(`webapp/assets/images/${imageName}`);
}

export function getImageSrc(
  imageUrl: string | undefined | null,
  randomize: boolean = false,
): string | undefined {
  if (imageUrl === undefined || imageUrl === null) {
    return undefined;
  }
  return imageUrl.startsWith("data:") ? imageUrl : getAzureUrl(imageUrl, randomize);
}

export function getIconUrl(iconName: string | undefined | null): string | undefined {
  if (iconName === undefined || iconName === null) {
    return undefined;
  }
  return getAzureUrl(`webapp/assets/icons/${iconName}.svg`);
}

export function getSubjectIconUrl(subjectName: string, isColor: boolean): string {
  if (subjectName === undefined) {
    subjectName = MultiSubjectName;
  }
  const iconName = subjectName.replaceAll(" ", "").toLowerCase().toString();
  const iconType = isColor ? "color" : "wireframe";
  return getAzureUrl(`webapp/assets/icons/subjects/${iconType}/${iconName}.svg`);
}

export function getNavbarIconUrl(iconName: string | undefined | null): string | undefined {
  if (iconName === undefined || iconName === null) {
    return undefined;
  }
  return getAzureUrl(`webapp/assets/icons/navbar/${iconName}.svg`);
}

export function getAlphabetIconUrl(userName: string, isUppercase: boolean): string {
  let iconName = null;
  iconName = userName.replaceAll(" ", "").toLowerCase().toString();
  iconName = iconName.length > 0 ? iconName[0] : null;
  if (iconName === null || !/[a-zA-Z]/g.test(iconName)) {
    iconName = "x";
  }
  const iconType = isUppercase ? "uppercase" : "lowercase";
  return getAzureUrl(`webapp/assets/icons/alphabets/${iconType}/${iconName}.svg`);
}

export function getVideoEmbedUrl(videoUrl: string, isThumbnail: boolean): string | undefined {
  const regexMatch = videoUrl.match(YoutubeUrlRegex);
  const youtubeId = regexMatch && regexMatch[7].length === 11 ? regexMatch[7] : undefined;
  const vimeoProps = isThumbnail ? "?background=1&autoplay=0" : "?title=0&byline=0";
  return youtubeId
    ? `${YoutubeBaseUrl}${youtubeId}?${YoutubeQueryParams}`
    : `${videoUrl}${vimeoProps}`;
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function copyToClipboard(textToCopy: string | null, textDescription: string) {
  if (textToCopy === null) {
    return;
  }
  navigator.clipboard.writeText(textToCopy);
  ElMessage({ message: `${textDescription} copied to clipboard!`, type: "success" });
}

export async function pasteImageFromClipboard() {
  const clipboardItems = await navigator.clipboard.read();
  for (let i = 0, len = clipboardItems.length; i < len; i++) {
    if (clipboardItems[i].types.includes("image/png")) {
      const clipboardData = await clipboardItems[i].getType("image/png");
      const encodedFile = await convertBlobToBase64(clipboardData);
      return encodedFile;
    }
  }
}

export function convertMinutesToDuration(
  timeInMinutes: number | undefined,
  minimumThreshold: number = 1,
  shortVersion: boolean = false,
): string | undefined {
  if (timeInMinutes === undefined || timeInMinutes <= minimumThreshold) {
    return;
  }
  return convertSecondsToDuration(timeInMinutes * 60, minimumThreshold * 60, shortVersion);
}

export function convertSecondsToDuration(
  timeInSeconds: number | undefined,
  minimumThreshold: number = 60,
  shortVersion: boolean = false,
): string | undefined {
  if (timeInSeconds === undefined || timeInSeconds <= minimumThreshold) {
    return;
  }
  const roundedTimeInSecs = minimumThreshold * Math.floor(timeInSeconds / minimumThreshold);
  interface durationDictionary {
    [key: string]: number;
  }
  const duration = intervalToDuration({
    start: 0,
    end: roundedTimeInSecs * 1000,
  }) as durationDictionary;
  Object.keys(duration).forEach((key) => {
    if (duration[key] === 0) {
      delete duration[key];
    }
  });
  const formttedDuration = Object.keys(duration)
    .map((key) => {
      const timeName = shortVersion ? key[0] : ` ${duration[key] === 1 ? key.slice(0, -1) : key}`;
      return `${duration[key]}${timeName}`;
    })
    .join(" ");
  return formttedDuration;
}

export function convertToTreeData(
  data: Array<object>,
  treeLevels: Array<string>,
): Array<IdNameChildren> {
  const idNameChildren: Array<IdNameChildren> = [];
  for (let i = 0; i < data.length; i++) {
    let children = undefined;
    const currentItem = data[i];
    const currentIdName = data[i] as IdNamePair;
    const typedContentType = treeLevels[0] as keyof typeof currentItem;
    if (treeLevels.length > 0 && data[i][typedContentType] !== undefined) {
      children = convertToTreeData(data[i][typedContentType], treeLevels.slice(1));
      idNameChildren.push({ Id: currentIdName.Id, Name: currentIdName.Name, Children: children });
    } else {
      idNameChildren.push({ Id: currentIdName.Id, Name: currentIdName.Name });
    }
  }
  return idNameChildren;
}

export function addParentMappingToTreeData(
  allTreeNodes: Array<CustomTreeNode> | undefined,
  parentSearchText: string | undefined | null,
) {
  if (!allTreeNodes) {
    return;
  }
  for (let i = 0; i < allTreeNodes.length; i++) {
    allTreeNodes[i].SearchText = allTreeNodes[i].Name;
    if (parentSearchText) {
      allTreeNodes[i].SearchText += ` ${parentSearchText}`;
    }
    const childNodes = allTreeNodes[i].Children;
    if (childNodes === undefined || childNodes.length === 0) {
      continue;
    }
    addParentMappingToTreeData(childNodes, allTreeNodes[i].SearchText);
  }
}

export function getCleanEnumName(enumKey: string) {
  if (enumKey === undefined) {
    return "";
  }
  return toTitleCase(enumKey.replaceAll("_", " "));
}

export function getIdNamePairsFromEnum(
  optionsEnum: any,
  sortByName: boolean = false,
): Array<IdNamePair> {
  const idNamePairs: Array<IdNamePair> = [];
  const enumKeys = Object.keys(optionsEnum).filter((v) => isNaN(Number(v)));
  enumKeys.forEach((key) => {
    const cleanName = getCleanEnumName(key);
    idNamePairs.push({ Id: optionsEnum[key], Name: cleanName });
  });
  if (sortByName) {
    sortIdNamePairsByName(idNamePairs);
  }
  return idNamePairs;
}

export function sortIdNamePairsByName(idNamePairs: Array<IdNamePair>) {
  idNamePairs.sort(function (a, b) {
    return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
  });
}
export function getIdNamePairById(
  IdNamePairs: Array<IdNamePair> | null,
  id: number,
): IdNamePair | undefined {
  if (!IdNamePairs) {
    return undefined;
  }
  return IdNamePairs.find((pair) => pair.Id === id);
}

export function sortNullableNumbers(a: number | null, b: number | null) {
  if (a == b) {
    return 0;
  }
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  return a > b ? 1 : -1;
}

export function getNameFromEnumId(id: number, optionsEnum: any): string {
  return getCleanEnumName(optionsEnum[id]);
}

export function getNameFromEnumString(id: string, optionsEnum: any): string | null {
  const matchingKey = Object.keys(optionsEnum).find(
    (key) => optionsEnum[key as keyof typeof optionsEnum] === id.trim(),
  );
  return matchingKey ? getCleanEnumName(matchingKey) : null;
}

export function toTitleCase(str: string): string {
  return str.toLowerCase().replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
}

export function splitStringByUpperCase(str: string): string {
  const strParts = str.match(/[A-Z][a-z]+/g);
  return strParts ? strParts.join(" ") : str;
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getFormattedDate(datetimeString: string, dateFormat: string = "dd MMM yyyy") {
  return format(parseISO(datetimeString), dateFormat);
}

export function getFormattedDateTime(datetimeString: string) {
  return format(parseISO(datetimeString), "dd MMM h:mmaaaaa'm'");
}

export function getFormattedTime(datetimeString: string) {
  return format(parseISO(datetimeString), "HH:mm");
}

export function getDateTimeString(datetime: Date) {
  return format(datetime, "yyyy-MM-dd HH:mm");
}

export function getClientSideISTDate() {
  const isoString = new Date().toISOString();
  return toZonedTime(parseISO(isoString), import.meta.env.VITE_TIME_ZONE);
}

export function getTimeStampForIST(dateTime: Date) {
  return dateTime.valueOf();
}

export function getDateString(datetime: Date, dateFormat: string = "yyyy-MM-dd") {
  return format(datetime, dateFormat);
}

export function getDatesInMonth(currentDate: Date): Array<Date> {
  const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const days = [];
  while (date.getMonth() === currentDate.getMonth()) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function getFormattedDuration(timeInSeconds: number): string {
  const duration = intervalToDuration({ start: 0, end: timeInSeconds * 1000 });
  const zeroPad = (n: number | undefined) => (n ? n : 0).toString().padStart(2, "0");
  if (duration.days) {
    duration.hours = duration.days * 24 + (duration.hours ? duration.hours : 0);
  }
  const formattedDuration = [duration.hours, duration.minutes, duration.seconds]
    .map(zeroPad)
    .join(":");
  return formattedDuration;
}

export function getFormattedDurationWithUnits(
  timeInSeconds: number,
  isSmall: boolean = false,
  includeDays: boolean = false,
): string {
  const duration = intervalToDuration({ start: 0, end: timeInSeconds * 1000 });
  if (!includeDays && duration.days) {
    duration.hours = duration.days * 24 + (duration.hours ? duration.hours : 0);
  }

  const formattedDuration = [];
  if (includeDays && duration.days) {
    formattedDuration.push(`${duration.days} ${isSmall ? "d" : "days"}`);
  }
  if (duration.hours) {
    formattedDuration.push(`${duration.hours} ${isSmall ? "h" : "hrs"}`);
  }
  if (duration.minutes) {
    formattedDuration.push(`${duration.minutes} ${isSmall ? "m" : "min"}`);
  }
  if (duration.seconds) {
    formattedDuration.push(`${duration.seconds} ${isSmall ? "s" : "sec"}`);
  }
  return formattedDuration.slice(0, 2).join(" ");
}

export function getFormattedPercentage(percentValue: number, precision: number) {
  return Number(percentValue).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: precision,
  });
}

export function getNMoreString(stringValues: string | Array<string> | undefined) {
  if (!stringValues) {
    return "";
  } else if (Array.isArray(stringValues)) {
    if (stringValues.length > 1) {
      return `${stringValues[0]} +${stringValues.length - 1} more`;
    } else {
      return stringValues[0];
    }
  } else {
    return stringValues;
  }
}

export function getDateRangeDisplay(dateRange: Array<string>) {
  if (!dateRange) {
    return null;
  }
  let startDate = getFormattedDate(dateRange[0]);
  let endDate = getFormattedDate(dateRange[1]);
  if (startDate === endDate) {
    return startDate;
  }
  const commonSubstring = commonTrailingSubstring(startDate, endDate);
  const commonSpaceIndex = commonSubstring.indexOf(" ");
  const cutoffSubstring = commonSubstring.substring(commonSpaceIndex).trim();
  startDate = startDate.replace(cutoffSubstring, "").trim();
  endDate = endDate.replace(cutoffSubstring, "").trim();
  return `${startDate} - ${endDate} ${cutoffSubstring}`;
}

export function commonTrailingSubstring(string1: string, string2: string) {
  let i = string1.length;
  let j = string2.length;
  while (i >= 0 && j >= 0 && string1.charAt(i) === string2.charAt(j)) {
    i--;
    j--;
  }
  return string1.substring(i + 1);
}

export function getContentArray(contentValue: number | Array<number> | null) {
  return Array.isArray(contentValue) ? contentValue : contentValue === null ? [] : [contentValue];
}

export function showCustomMessage(messageDetails: CustomMessage) {
  if (messageDetails.messageText === undefined) {
    return;
  }
  if (
    messageDetails.duration !== undefined &&
    (messageDetails.duration === 0 || messageDetails.duration >= 5)
  ) {
    messageDetails.showClose = true;
  }
  const sessionStore = useSessionStore();
  const { ActiveCustomMessages } = storeToRefs(sessionStore);
  const customMessage = ElMessage({
    //@ts-expect-error Typing error from element-plus
    message: messageDetails.messageText,
    type: messageDetails.messageType ? messageDetails.messageType : "info",
    duration: (messageDetails.duration !== undefined ? messageDetails.duration : 5) * 1000,
    grouping: messageDetails.enableGrouping ? messageDetails.enableGrouping : true,
    showClose: !!messageDetails.showClose,
    offset: 60,
    customClass: "custom-message",
  });
  if (!messageDetails.isSticky) {
    ActiveCustomMessages.value.push(customMessage);
  }
}

export async function showCustomNotification(
  messageDetails: CustomNotification,
  defaultDuration: number = 10,
  positionOffset: number = 80,
) {
  if (messageDetails.messageTitle === undefined || messageDetails.messageText === undefined) {
    return;
  }
  messageDetails.duration =
    messageDetails.duration !== undefined ? messageDetails.duration : defaultDuration;
  if (messageDetails.duration >= 10) {
    messageDetails.showClose = true;
  }
  const messagePosition = messageDetails.messagePosition
    ? messageDetails.messagePosition
    : ("bottom-left" as any);

  return await ElNotification({
    title: messageDetails.messageTitle,
    message: messageDetails.messageText,
    icon: h(NotificationIcon, { iconName: messageDetails.messageIcon }),
    duration: messageDetails.duration * 1000,
    showClose: !!messageDetails.showClose,
    position: messagePosition,
    offset: positionOffset,
    customClass: `custom-notification-${messageDetails.messageType}`,
  });
}

export function downloadCsvData(
  objectData: Array<any>,
  objectKeys: Array<string> | null,
  fileName: string,
) {
  const flattenedData = objectData.map((o) => flattenObject(o));
  let objectHeaders = Object.keys(flattenedData[0]);
  if (objectKeys !== null) {
    objectHeaders = objectKeys.filter((ok) => objectHeaders.includes(ok));
  }
  const objectRows = flattenedData.map((o) => objectHeaders.map((h) => o[h]));
  const escapedRows = objectRows.map((row) => {
    const newRow = row.map((col) => {
      return col === undefined || col === null ? "" : `"${col}"`;
    });
    return newRow;
  });
  const headerRow = objectHeaders.join(",");
  const csvRows = [headerRow, ...escapedRows.map((row) => row.join(","))];
  const csvData = csvRows.join("\n");
  fileName = `${fileName}.csv`;
  const isSuccess = useSpeedlabsStore().callFlutterHandler("onDownloadCsv", {
    csvData: csvData,
    fileName: fileName,
  });
  if (!isSuccess) {
    downloadFile(csvData, "text/csv;charset=utf-8", fileName);
  }
}

export async function downloadPngFile(
  fileUrl: string,
  fileName: string,
  randomize: boolean = false,
) {
  await downloadFileFromUrl(fileUrl, "image/png", fileName, randomize);
}

export async function downloadCsvFile(
  csvUrl: string,
  fileName: string,
  randomize: boolean = false,
) {
  await downloadFileFromUrl(csvUrl, "text/csv", fileName, randomize);
}

export async function downloadPdf(pdfUrl: string, fileName: string, randomize: boolean = false) {
  await downloadFileFromUrl(pdfUrl, "application/pdf", fileName, randomize);
}

export async function downloadZip(zipUrl: string, fileName: string, randomize: boolean = false) {
  await downloadFileFromUrl(zipUrl, "application/zip", fileName, randomize);
}

export async function downloadFileFromUrl(
  fileUrl: string,
  fileType: string,
  fileName: string,
  randomize: boolean = false,
) {
  if (fileUrl.startsWith("~")) {
    fileUrl = getAzureUrl(fileUrl);
  }
  if (randomize) {
    const connector = fileUrl.includes("?") ? "&" : "?";
    fileUrl = `${fileUrl}${connector}random=${Math.random().toString()}`;
  }
  const isSuccess = useSpeedlabsStore().callFlutterHandler("onDownloadFile", {
    url: fileUrl,
    fileType: fileType,
    fileName: fileName,
  });
  if (!isSuccess) {
    axios({ url: fileUrl, method: "get", responseType: "arraybuffer" })
      .then((response) => {
        downloadFile(response.data, fileType, fileName);
      })
      .catch(() => {
        showCustomMessage({
          messageText: "Oops! There was an error downloading the file.",
          messageType: "error",
        });
      });
  }
}

function downloadFile(fileData: BlobPart, fileType: string, fileName: string) {
  const fileBlob = new Blob([fileData], { type: fileType });
  const downloadLink = document.createElement("a");
  const downloadHref = URL.createObjectURL(fileBlob);
  downloadLink.href = downloadHref;
  downloadLink.setAttribute("download", fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadHref);
}

function flattenObject(nestedObject: any, prefix = "") {
  const flattenedObject: any = {};
  Object.keys(nestedObject).forEach((key) => {
    if (typeof nestedObject[key] === "object" && nestedObject[key] !== null) {
      Object.assign(flattenedObject, flattenObject(nestedObject[key], key));
    } else {
      const flatKey = prefix ? `${prefix}.${key}` : key;
      flattenedObject[flatKey] = nestedObject[key];
    }
  });
  return flattenedObject;
}

export async function getEncodedUploadFile(uploadFile: Array<UploadUserFile> | undefined) {
  if (!uploadFile || uploadFile.length === 0) {
    return;
  }
  const allEncodedFiles = await getEncodedUploadFiles([uploadFile[0]]);
  return allEncodedFiles ? allEncodedFiles[0] : undefined;
}

export async function getEncodedUploadFiles(uploadFiles: Array<UploadUserFile>) {
  if (!uploadFiles || uploadFiles.length === 0) {
    return;
  }
  const allEncodedFiles: Array<string> = [];
  for (let i = 0; i < uploadFiles.length; i++) {
    const fileContent = uploadFiles[i].raw;
    if (fileContent) {
      try {
        const encodedFile = await convertBlobToBase64(fileContent);
        if (encodedFile) {
          allEncodedFiles.push(encodedFile);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  return allEncodedFiles;
}

export async function convertBlobToBase64(fileContent: Blob): Promise<string | undefined> {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.readAsDataURL(fileContent);
    fileReader.onload = async () => {
      resolve(fileReader.result ? fileReader.result.toString() : undefined);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function deepCopyObject(obj: object): object {
  return JSON.parse(JSON.stringify(obj));
}

export function getUniqueObjectsByKey(objectArray: Array<object>, key: string): Array<object> {
  const objKey = key as keyof (typeof objectArray)[0];
  return [...new Map(objectArray.map((item) => [item[objKey], item])).values()];
}

export function generateRandomString(stringLength: number) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < stringLength; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
