import { handleApiError, handleApiResponse } from "@/methods/api";
import ApiVerified from "@/services/apiVerified";
import {
  PracticeSessionInfo,
  SelfPracticeEvent,
  SelfPracticeInputs,
  SelfPracticeQuestionContent,
  SelfPracticeSessionState,
} from "@/types/conductSelfPractice";
import { ApiResponse } from "@/types/global";
import { SelfPracticeReport, SelfPracticeReportDetails } from "@/types/studentSelfPracticeReport";

export default class PracticeApi extends ApiVerified {
  async createSelfPractice(selfPracticeInputs: SelfPracticeInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `self-practice/create-session`,
        selfPracticeInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startSelfPractice(selfPracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<SelfPracticeSessionState>(
        `self-practice/start-self-practice`,
        {
          selfPracticeId: selfPracticeId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfPracticeQuestionContent(
    courseChapterId: number,
    kscId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SelfPracticeQuestionContent>(
        `self-practice/get-self-practice-question-content`,
        { params: { courseChapterId: courseChapterId, kscId: kscId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async pushUnsavedSelfPracticeEvents(
    selfPracticeId: number,
    practiceEvents: Array<SelfPracticeEvent>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `self-practice/push-self-practice-events`,
        { selfPracticeId: selfPracticeId, practiceEvents: practiceEvents },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveSelfPracticeSessionState(sessionState: SelfPracticeSessionState): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `self-practice/save-self-practice-session-state`,
        sessionState,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async pauseSelfPractice(selfPracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(`self-practice/pause-self-practice`, {
        selfPracticeId: selfPracticeId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endSelfPractice(sessionState: SelfPracticeSessionState): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `self-practice/end-self-practice`,
        sessionState,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endPausedPracticeSession(selfPracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `self-practice/end-paused-practice-session`,
        {
          selfPracticeId: selfPracticeId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfPracticePausedSessions(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PracticeSessionInfo>>(
        `/self-practice/get-practice-paused-sessions`,
        {
          params: { userId: userId, courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfPracticeCompletedSessions(
    userId: number,
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PracticeSessionInfo>>(
        `/self-practice/get-practice-completed-sessions`,
        {
          params: { userId: userId, courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentSelfPracticeReport(
    selfPracticeId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SelfPracticeReport>(
        `/self-practice/get-self-practice-report`,
        {
          params: { selfPracticeId: selfPracticeId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentSelfPracticeReportDetails(
    selfPracticeId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SelfPracticeReportDetails>(
        `/self-practice/get-self-practice-report-details`,
        {
          params: { selfPracticeId: selfPracticeId, userId: userId, userRole: userRole },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveSelfPracticeReport(selfPracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/self-practice/save-self-practice-report`,
        {
          selfPracticeId: selfPracticeId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
