<template>
  <div v-if="isMenuRequired">
    <el-popover ref="actionableMenuRef" :width="185" trigger="click">
      <template #reference>
        <div class="bi bi-three-dots-vertical fs-l tightest color-white cursor-pointer"></div>
      </template>
      <template #default>
        <div class="px-1">
          <div v-for="menuItem in RouteActionableMenuItems">
            <div
              v-if="isMenuItemEnabled(menuItem)"
              class="popover-item-grid color-info cursor-pointer mb-2"
              @click="onMenuClicked(menuItem)"
            >
              <i :class="`bi bi-${menuItem.icon} fs-s`"></i>
              <div class="fs-xs">{{ menuItem.displayName }}</div>
            </div>
          </div>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<script lang="ts">
import { RouteActionableMenuItems } from "@/constants/global";
import { useSessionStore } from "@/stores/sessionStore";
import { MenuItem, RouteActionableMenuItem } from "@/types/global";
import { storeToRefs } from "pinia";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "RouteActionableMenu",
  setup() {
    const sessionStore = useSessionStore();
    const { navbarMenu, IsMobileDevice } = storeToRefs(sessionStore);
    const currentMenuItem = ref<MenuItem>();
    const actionableMenuRef = ref();
    const currentRoute = useRoute();

    onMounted(() => {
      if (navbarMenu.value === null) {
        return;
      }
      currentMenuItem.value = undefined;
      for (let m = 0; m < navbarMenu.value.MenuItems.length; m++) {
        const menuItem = navbarMenu.value.MenuItems[m];
        if (menuItem.MenuName === currentRoute.name) {
          currentMenuItem.value = menuItem;
          break;
        }
        if (menuItem.ChildItems) {
          for (let c = 0; c < menuItem.ChildItems.length; c++) {
            const childItem = menuItem.ChildItems[c];
            if (childItem.MenuName === currentRoute.name) {
              currentMenuItem.value = childItem;
              break;
            }
          }
        }
        if (currentMenuItem.value !== undefined) {
          break;
        }
      }
    });

    const isMenuRequired = computed(() => {
      if (!IsMobileDevice.value || currentMenuItem.value === undefined) {
        return false;
      }
      for (const menuItem of RouteActionableMenuItems) {
        if (isMenuItemEnabled(menuItem)) {
          return true;
        }
      }
      return false;
    });

    function isMenuItemEnabled(menuItem: RouteActionableMenuItem) {
      if (currentMenuItem.value === undefined) {
        return false;
      }
      const itemKey = menuItem.id as keyof typeof currentMenuItem.value;
      return currentMenuItem.value[itemKey];
    }

    function onMenuClicked(menuItem: RouteActionableMenuItem) {
      menuItem.function();
      actionableMenuRef.value.hide();
    }

    return {
      RouteActionableMenuItems,
      isMenuRequired,
      actionableMenuRef,
      currentMenuItem,
      isMenuItemEnabled,
      onMenuClicked,
    };
  },
});
</script>
<style lang="scss" scoped>
.popover-item-grid {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  &:hover {
    color: $color-primary !important;
  }
}
</style>
