export enum UserRolesEnum {
  ACADEMIC_EXCELLENCE_MANAGER = "Academic Excellence Manager",
  ADMIN = "Admin",
  AFFILIATION_HEAD = "Affiliation Head",
  B2C_INDIVIDUAL_TEACHER = "B2C Individual Teacher",
  CENTER_MANAGER = "Center Manager",
  CONTENT_HEAD = "Content Head",
  CONTENT_MANAGER = "Content Manager",
  DEACTIVE_DEMO_STUDENT = "Deactive Demo Student",
  DEMO_STUDENT = "Demo Student",
  FACULTY = "Faculty",
  MODERATOR = "Moderator",
  PARENT = "Parent",
  SCHOOL_PARTNER_RM = "School Partner RM",
  SCHOOL_TEACHER = "SchoolTeacher",
  STUDENT = "Student",
  TUTOR = "Tutor",
  TUTORING_ADMIN = "Tutoring Admin",
  TUTORING_OPS = "Tutoring Ops",
  TUTORING_SALES = "Tutoring Sales",
  TUTORING_STUDENT = "Tutoring Student",
}

export enum UserRoleIdsEnum {
  ACADEMIC_EXCELLENCE_MANAGER = 21,
  ADMIN = 1,
  AFFILIATION_HEAD = 7,
  B2C_INDIVIDUAL_TEACHER = 19,
  CENTER_MANAGER = 3,
  CONTENT_HEAD = 5,
  CONTENT_MANAGER = 14,
  DEACTIVE_DEMO_STUDENT = 9,
  DEMO_STUDENT = 8,
  FACULTY = 4,
  MODERATOR = 12,
  PARENT = 18,
  SCHOOL_PARTNER_RM = 22,
  SCHOOL_TEACHER = 20,
  STUDENT = 2,
  TUTOR = 11,
  TUTORING_ADMIN = 13,
  TUTORING_OPS = 16,
  TUTORING_SALES = 17,
  TUTORING_STUDENT = 6,
}

export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
}

export enum NavbarModeEnum {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum WeekdaysEnum {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export enum PdfViewerTemplateEnum {
  SINGLE = 0,
  DOUBLE = 1,
  NO_CONTROLS = 2,
}

export enum CustomFilterTypeEnum {
  DROPDOWN = "Dropdown",
  MULTI_SELECT = "MultiSelect",
  RADIO_BUTTON = "RadioButton",
  BATCH_TREE_SELECT = "BatchTreeSelect",
  DATE_PICKER = "DatePicker",
  DATE_RANGE_PICKER = "DateRangePicker",
}

export enum QuestionSelectorSortEnum {
  DIFFICULTY = 1,
  CONCEPTS = 2,
  NEWEST = 3,
}

export enum ActiveInactiveStatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum ActivityTypesEnum {
  REFERRAL = 1,
  LOGIN = 2,
  LEARN = 3,
  LIVE_CLASS = 4,
  PRACTICE = 5,
  SELF_TEST = 6,
  INSTITUTE_TEST = 7,
  ASSIGNMENT = 8,
  PRACTICE_V1 = 95,
  SELF_TEST_V1 = 96,
  INSTITUTE_TEST_V1 = 97,
  ASSIGNMENT_V1 = 98,
}

export enum ActivityNamesEnum {
  REFERRAL = "Referral",
  LOGIN = "Login",
  LEARN = "Learn",
  LIVE_CLASS = "LiveClass",
  PRACTICE = "Practice",
  SELF_TEST = "SelfTest",
  INSTITUTE_TEST = "InstituteTest",
  ASSIGNMENT = "Assignment",
  CONCEPTS = "Concepts",
  PRACTICE_V1 = "PracticeV1",
  SELF_TEST_V1 = "SelfTestV1",
  INSTITUTE_TEST_V1 = "InstituteTestV1",
  ASSIGNMENT_V1 = "AssignmentV1",
}

export enum RecommendationTypeEnum {
  PRACTICE = "Practice",
  ASSIGNMENTS = "Assignments",
  TESTS = "Tests",
  VIDEOS = "Videos",
}

export enum DatePeriodOptionsEnum {
  "1 Week" = 7,
  "2 Weeks" = 14,
  "1 Month" = 30,
  "3 Month" = 90,
}

export enum BugCategoriesEnum {
  "Missing Content" = 1,
  "Incorrect Content" = 2,
  "Feature Not Working" = 3,
  "Other" = 4,
}

export enum PreloadedAssignmentCategoryEnum {
  AFFILIATION = 1,
  STUDENT = 2,
}

export enum PreloadedTestCategoryEnum {
  AFFILIATION = 1,
  STUDENT = 2,
  INTERNAL = 3,
}

export enum LearnDashboardActiveTabEnum {
  CONCEPT = "Concepts",
  VIDEOS = "Videos",
  STUDY_MATERIALS = "Study Materials",
}
