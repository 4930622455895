import { handleApiError, handleApiResponse } from "@/methods/api";
import { getClientSideISTDate } from "@/methods/utils";
import {
  AffiliationCustomAssets,
  ApiResponse,
  AppConfig,
  CourseForSignup,
  LoginWithOtp,
  LoginWithPassword,
  OtpResponse,
  RegisterCredentials,
  UserInfo,
} from "@/types/global";
import axios, { AxiosInstance } from "axios";
import { parseISO } from "date-fns";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default class ApiUnverified {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({ baseURL: import.meta.env.VITE_API_UNVERIFIED_URL });
  }

  async pingApiServer(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(`ping-server`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loginWithPassword(loginCredentials: LoginWithPassword): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<UserInfo>(
        `login-with-password`,
        loginCredentials,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async sendLoginOtp(loginId: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<OtpResponse>(`send-login-otp`, {
        loginId: loginId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loginWithOtp(loginCredentials: LoginWithOtp): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<UserInfo>(`login-with-otp`, loginCredentials);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getActiveLoginSession(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<number>(`get-active-login-session`, {
        params: { userId: userId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async expireLoginSession(loginSessionId: number, isSystemExpired: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`expire-login-session`, {
        loginSessionId: loginSessionId,
        isSystemExpired: isSystemExpired,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async registerB2CUser(registerCredentials: RegisterCredentials): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `register-b2c-user`,
        registerCredentials,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationCustomAssets(domainName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationCustomAssets>(
        `get-affiliation-custom-assets`,
        { params: { domainName: domainName } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getServerTime(): Promise<Date> {
    try {
      const response = await this.axiosInstance.get<string>(`server-time`);
      return parseISO(response.data);
    } catch (error) {
      return getClientSideISTDate();
    }
  }

  async getSpeedlabsConfig(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AppConfig>(`app-config`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async refreshAccessToken(refreshToken: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`refresh-access-token`, {
        params: { refreshToken: refreshToken },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCoursesForSignup(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<CourseForSignup>>(`courses-for-signup`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async sendRegisterOtp(mobileNumber: string, userName: string | undefined): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(`send-register-otp`, {
        mobileNumber: mobileNumber,
        userName: userName,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async validateOtp(otp: string, otpId: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`validate-otp`, {
        params: { otp: otp, otpId: otpId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
