<template>
  <div class="affiliation-selector-grid px-3 py-2 border bg-color-white">
    <div v-if="AffiliationTypeOptions" class="flexcol">
      <div class="flexrow xbetween ycenter mb-1">
        <CustomSwitch
          inputId="selectAffiliationsSwitch"
          :initialValue="SelectAffiliationsByName"
          inactiveLabel="By Type"
          activeLabel="By Name"
          :inactiveValue="false"
          :activeValue="true"
          :isSmall="true"
          @onChange="onAffiliationSelectTypeChange"
        />
        <CustomSwitch
          inputId="affiliationStatusSwitch"
          :initialValue="CurrentAffiliationStatus"
          inactiveLabel="All"
          activeLabel="Active Affiliations"
          :inactiveValue="ActiveInactiveStatusEnum.INACTIVE"
          :activeValue="ActiveInactiveStatusEnum.ACTIVE"
          :isSmall="true"
          @onChange="onAffiliationStatusChange"
        />
      </div>
      <div v-if="SelectAffiliationsByName" class="flexrow gap-1">
        <CustomInput
          inputId="adminAffiliationName"
          inputPlaceholder="Affiliation Name"
          :initialValue="CurrentAffiliationSearchName ? CurrentAffiliationSearchName : undefined"
          :key="CurrentAffiliationSearchName ? CurrentAffiliationSearchName : undefined"
          @onChange="onAffiliationNameChanged"
          class="flex-fill"
        />
        <div class="flexrow">
          <div class="button action" @click="onSearchByNameClicked">Search By Name</div>
        </div>
      </div>
      <div v-else>
        <CustomDropdown
          inputId="adminAffiliationType"
          inputPlaceholder="Select Affiliation Type"
          :dropdownItems="AffiliationTypeOptions ? AffiliationTypeOptions : undefined"
          :initialValue="CurrentAffiliationTypeId ? CurrentAffiliationTypeId : undefined"
          :isFilterable="true"
          :isMandatory="true"
          :key="CurrentAffiliationTypeId ? CurrentAffiliationTypeId : undefined"
          @onChange="onAffiliationTypeChange"
        />
      </div>
    </div>
    <CustomDropdown
      v-loading.fullscreen.lock="loadingAffiliations"
      inputId="adminAffiliation"
      inputPlaceholder="Select an Affiliation"
      :dropdownItems="AffiliationOptions ? AffiliationOptions : undefined"
      :initialValue="SelectedAffiliation ? SelectedAffiliation.Id : undefined"
      :isFilterable="true"
      :isMandatory="true"
      @onChange="onAffiliationChange"
      :key="ReloadAffiliationsKey"
    />
  </div>
</template>
<script lang="ts">
import ThePageTitleContainer from "@/components/ThePageTitleContainer.vue";
import CustomDropdown from "@/components/input/CustomDropdown.vue";
import CustomInput from "@/components/input/CustomInput.vue";
import CustomSwitch from "@/components/input/CustomSwitch.vue";
import CustomBatchTreeSelect from "@/components/input/affiliation/CustomBatchTreeSelect.vue";
import { ActiveInactiveStatusEnum } from "@/enums/global";
import { showCustomMessage } from "@/methods/utils";
import { useFilterableCardsStore } from "@/stores/filterableCardsStore";
import { useParentAffiliationStore } from "@/stores/parentAffiliationStore";
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "TheAffiliationSelector",
  components: {
    ThePageTitleContainer,
    CustomDropdown,
    CustomInput,
    CustomBatchTreeSelect,
    CustomSwitch,
  },
  setup() {
    const parentAffiliationStore = useParentAffiliationStore();
    const { loadAffiliations, onAffiliationChange } = parentAffiliationStore;
    const {
      SelectAffiliationsByName,
      AffiliationTypeOptions,
      AffiliationOptions,
      CurrentAffiliationTypeId,
      CurrentAffiliationStatus,
      CurrentAffiliationSearchName,
      SelectedAffiliation,
      ReloadAffiliationsKey,
    } = storeToRefs(parentAffiliationStore);
    const filterableCardsStore = useFilterableCardsStore();
    const { CardsReloadKey } = storeToRefs(filterableCardsStore);
    const affiliationSearchName = ref();
    const loadingAffiliations = ref(false);
    const loadingBatches = ref(false);

    function onAffiliationSelectTypeChange(selectType: boolean) {
      SelectAffiliationsByName.value = selectType;
      CurrentAffiliationTypeId.value = null;
      affiliationSearchName.value = null;
      AffiliationOptions.value = null;
      SelectedAffiliation.value = null;
      ReloadAffiliationsKey.value += 1;
    }
    async function onAffiliationTypeChange(affiliationTypeId: number) {
      CurrentAffiliationTypeId.value = affiliationTypeId;
      CurrentAffiliationSearchName.value = null;
      await reloadAffiliations();
    }
    async function onAffiliationNameChanged(affiliationName: string) {
      affiliationSearchName.value = affiliationName;
    }
    async function onSearchByNameClicked() {
      if (!affiliationSearchName.value || affiliationSearchName.value.length < 3) {
        showCustomMessage({
          messageText: "Minimum 3 characters required to search by name",
          messageType: "warning",
        });
      } else {
        CurrentAffiliationSearchName.value = affiliationSearchName.value;
        CurrentAffiliationTypeId.value = null;
        await reloadAffiliations();
      }
    }
    async function onAffiliationStatusChange(statusId: number) {
      CurrentAffiliationStatus.value = statusId;
      await reloadAffiliations();
    }
    async function reloadAffiliations() {
      loadingAffiliations.value = true;
      AffiliationOptions.value = null;
      SelectedAffiliation.value = null;
      await loadAffiliations();
      CardsReloadKey.value += 1;
      loadingAffiliations.value = false;
      ReloadAffiliationsKey.value += 1;
    }
    return {
      ActiveInactiveStatusEnum,
      loadingAffiliations,
      loadingBatches,
      SelectAffiliationsByName,
      AffiliationTypeOptions,
      AffiliationOptions,
      CurrentAffiliationTypeId,
      CurrentAffiliationSearchName,
      SelectedAffiliation,
      CurrentAffiliationStatus,
      ReloadAffiliationsKey,
      onAffiliationSelectTypeChange,
      onAffiliationTypeChange,
      onAffiliationNameChanged,
      onSearchByNameClicked,
      onAffiliationStatusChange,
      onAffiliationChange,
    };
  },
});
</script>
<style lang="scss" scoped>
.affiliation-selector-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-items: end;
  @include media("<=m") {
    grid-template-columns: 1fr;
  }
}
</style>

<style lang="scss">
.affiliation-selector-grid .input-label {
  margin-bottom: 0 !important;
}
</style>
